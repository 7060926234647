<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12" v-if="allowName">
          <v-text-field outlined :error-messages="nameErrors" :label="$t('name')" @blur="$v.item.name.$touch()"
            @input="$v.item.name.$touch()" required v-model="item.name" :disabled="isEnabled" />
        </v-col>
        <v-col cols="12" md="8">
          <v-autocomplete
            :error-messages="topicsErrors"
            :label="$t('Topics')"
            @blur="$v.item.topics.$touch()"
            @input="$v.item.topics.$touch()"
            required
            :items="availableTopics"
            v-model="currentTopics"
            item-value="element.@id"
            :disabled="isEnabled"
            multiple
            :filter="topicFilter"
            chips
          >
            <template slot="selection" slot-scope="data">
              <v-chip>{{ data.item.element.name }}</v-chip>
            </template>
            <template slot="item" slot-scope="data">
              <v-list-item-content :style="'padding-left: ' + topicPadding(data.item)">
                <template v-if="data.item.hasChildren === false">
                  <v-list-item-title>
                    {{ data.item.element.name }}
                  </v-list-item-title>
                </template>
                <template v-else>
                  <v-list-item-title>{{
                      data.item.element.name
                    }}
                  </v-list-item-title>
                </template>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="6" v-if="resolvedTimeSlot">
          <InputDate :error-messages="timeSlotStartErrors" :with-time="true" :label="$t('Start')" required
            v-model="resolvedTimeSlot.start" :disabled="isEnabled" @setInputDateTime="onStartDateSet" />
        </v-col>
        <v-col cols="12" md="6" v-if="resolvedTimeSlot">
          <InputDate :error-messages="timeSlotEndErrors" :with-time="true" :label="$t('End')" required
            v-model="resolvedTimeSlot.end" :disabled="isEnabled" @setInputDateTime="onEndDateSet" />
        </v-col>

        <v-col cols="12" md="6">
          <v-select hide-details="auto" :error-messages="speakerErrors" :label="$t('Speaker')"
            @blur="$v.item.speaker.$touch()" @input="$v.item.speaker.$touch()" required :items="availableSpeakers"
            v-model="item.speaker" item-value="@id" :disabled="isCancelled">
            <template slot="item" slot-scope="data">
              <v-list-item-content>
                <v-list-item-title>
                  <template v-if="data.item.profile">
                    {{ getProfileItemBasics(data.item.profile, "firstName") }}
                    {{ getProfileItemBasics(data.item.profile, "lastName") }}
                  </template>
                  <template v-else>
                    {data.item.email}
                  </template>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template slot="selection" slot-scope="data">
              <template v-if="data.item.profile">
                {{ getProfileItemBasics(data.item.profile, "firstName") }}
                {{ getProfileItemBasics(data.item.profile, "lastName") }}
              </template>
              <template v-else>
                {data.item.email}
              </template>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-select hide-details="auto" :error-messages="coSpeakerErrors" :label="$t('CoSpeaker')"
            @blur="$v.item.coSpeaker.$touch()" @input="$v.item.coSpeaker.$touch()" required :items="availableCoSpeakers"
            v-model="item.coSpeaker" item-value="@id" clearable :disabled="isCancelled">
            <template slot="item" slot-scope="data">
              <v-list-item-content>
                <v-list-item-title>
                  <template v-if="data.item.profile">
                    {{ getProfileItemBasics(data.item.profile, "firstName") }}
                    {{ getProfileItemBasics(data.item.profile, "lastName") }}
                  </template>
                  <template v-else>
                    {{ data.item.email }}
                  </template>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template slot="selection" slot-scope="data">
              <template v-if="data.item.profile">
                {{ getProfileItemBasics(data.item.profile, "firstName") }}
                {{ getProfileItemBasics(data.item.profile, "lastName") }}
              </template>
              <template v-else>
                {data.item.email}
              </template>
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field type="number" outlined hide-details="auto" :error-messages="teachingUnitsErrors"
            :label="$t('teachingUnits')" @blur="$v.item.teachingUnits.$touch()" @input="$v.item.teachingUnits.$touch()"
            required v-model.number="item.teachingUnits" :disabled="isEnabled" />
        </v-col>
        <v-col cols="6" md="4">
          <v-text-field outlined hide-details="auto" :label="$t('fee')" :error-messages="feeErrors"
            @blur="$v.item.fee.$touch()" @input="$v.item.fee.$touch()" prefix="€" required v-model.number="item.fee"
            :placeholder="defaultModuleFee" :disabled="isEnabled" />
        </v-col>
        <v-col cols="6" md="4">
          <v-text-field outlined hide-details="auto" :error-messages="maxParticipantsErrors"
            :label="$t('maxParticipants')" @blur="$v.item.maxParticipants.$touch()"
            @input="$v.item.maxParticipants.$touch()" required v-model.number="item.maxParticipants"
            :placeholder="defaultParticipants" :disabled="isCompleted" />
        </v-col>

        <v-col cols="12">
          <h3>{{ $t("seminarContent") }}</h3>
          <TextTemplateSelector :disabled="isEnabled || isCompleted" @textTemplateSelected="
            templateSelected('seminarContent', ...arguments)
            " />
          <InputEditor v-model="item.seminarContent" :error-messages="seminarContentErrors" :disabled="isCompleted" />
        </v-col>
        <v-col cols="12">
          <h3>{{ $t("additionalConfirmationText") }}</h3>
          <TextTemplateSelector :disabled="isEnabled || isCompleted" @textTemplateSelected="
            templateSelected('additionalConfirmationText', ...arguments)
            " />
          <InputEditor v-model="item.additionalConfirmationText" :error-messages="additionalConfirmationTextErrors"
            :disabled="isCompleted" />
        </v-col>

        <v-col cols="12">
          <v-autocomplete
            v-if="this.currentSeminarSettings.offerable && offers"
            v-model="selectedOffer"
            :items="offers"
            :label="$t('LinkWithOffer')"
            :filter="offerFilter"
            v-on:input="onOfferAcUpdated"
            clearable
            outlined
          >
            <template v-slot:selection="data">
              <v-chip v-bind="data.attrs" :input-value="data.selected">
                {{ data.item.number }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{data.item.number}}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="6" sm="6" v-if="this.currentSeminarSettings.noOnline">
          <v-checkbox :error-messages="noOnlineErrors" :label="$t('noOnline')" @blur="$v.item.noOnline.$touch()"
            @input="$v.item.noOnline.$touch()" v-model="item.noOnline" />
        </v-col>
        <template v-if="item.noOnline && this.currentSeminarSettings.presenceSeminarsEnabled">
          <v-col cols="12" md="6" sm="6">
            <v-checkbox :error-messages="noOnlineErrors" :label="$t('presenceSeminar')"
              @blur="$v.item.presenceSeminar.$touch()" @input="$v.item.presenceSeminar.$touch()"
              v-model="item.presenceSeminar" />
          </v-col>
          <template v-if="item.presenceSeminar">
            <v-col>
              <v-select v-if="venues" :items="venueItems" :label="$t('Venue')" v-model="item.venue" item-value="@id"
                :disabled="isCompleted">
                <template slot="selection" slot-scope="data">{{
                  data.item.name
                }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="`${data.item.name}`" />
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
          </template>
        </template>

        <v-col cols="12" v-if="!item.noOnline">
          <v-checkbox :label="$t('externalMeetingRoom')" v-model="externalMeetingToggle"
                      @click="toggleExternalMeetingRoom" />
          <template v-if="externalMeetingToggle">
            <v-text-field outlined hide-details="auto" :label="$t('joinUrl')" v-model="item.externalMeeting.joinUrl"
                          :error-messages="joinUrlErrors" @blur="$v.item.externalMeeting.joinUrl.$touch()"
                          @input="$v.item.externalMeeting.joinUrl.$touch()" /><br />
            <v-text-field outlined hide-details="auto" :label="$t('externalMeetingTitle')"
                          v-model="item.externalMeeting.title" /><br />
            <v-text-field outlined hide-details="auto" :label="$t('externalMeetingPassword')"
                          v-model="item.externalMeeting.password" /><br />
          </template>
        </v-col>

        <v-col cols="12" v-if="this.isInhouse && this.item.offer">
          <v-checkbox :label="$t('billingToCustomerForNumberOfParticipants')" v-model="item.billingPerParticipant" />
        </v-col>

        <v-col cols="12"  v-if="this.isInhouse">
          <v-select hide-details="auto" :error-messages="attendanceListTemplateErrors" :label="$t('attendanceListTemplate')"
                    @blur="$v.item.attendanceListTemplate.$touch()" @input="$v.item.attendanceListTemplate.$touch()" required :items="attendanceListTemplates"
                    v-model="item.attendanceListTemplate" item-value="@id" :disabled="isCancelled">
            <template slot="item" slot-scope="data">
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('attendanceListTemplate_' + data.item.name) }}
                </v-list-item-title>
               </v-list-item-content>
            </template>
            <template slot="selection" slot-scope="data">
              {{ $t('attendanceListTemplate_' + data.item.name) }}
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from "lodash/has";
import { validationMixin } from "vuelidate";
import { required, requiredIf, url } from "vuelidate/lib/validators";
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import TenantMixin from "@/mixins/TenantMixin";
import TopicMixin from "@/mixins/TopicMixin";
import ProfileMixin from "@/mixins/ProfileMixin";
import TenantUserMixin from "@/mixins/TenantUserMixin";
import InputDate from "@/components/InputDate";
import InputEditor from "@/components/InputEditor";
import TextTemplateSelector from "@/components/textTemplate/Selector";
import SpeakerMixin from "@/mixins/SpeakerMixin";
import AttendanceListTemplateMixin from "@/mixins/AttendanceListTemplateMixin";

export default {
  name: "SeminarForm",
  mixins: [
    validationMixin,
    TopicMixin,
    TenantMixin,
    ProfileMixin,
    TenantUserMixin,
    SpeakerMixin,
    AttendanceListTemplateMixin
  ],
  data() {
    return {
      selectedOffer: null,
      startDateSet: false,
      endDateSet: false,
      speakersForActiveTenant: null,
      externalMeetingToggle: false,
    };
  },
  components: {
    InputDate,
    InputEditor,
    TextTemplateSelector,
  },
  props: {
    values: {
      type: Object,
      required: true,
    },
    allowName: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    errors: {
      type: Object,
      default: () => {
      },
    },
    initialValues: {
      type: Object,
      default: () => {
      },
    },
    isEnabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isCancelled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isCompleted: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    ...mapFields("offer", {
      offers: "selectItems",
    }),
    ...mapFields("venue", {
      venues: "selectItems",
    }),
    currentTopics: {
      get: function() {

        return this.item.topics ? this.item.topics.map((t) => typeof t.topic !== 'undefined' ? t.topic : t) : null
      },
      set: function (val) {
        this.item.topics = val;
      }
    },
    venueItems() {
      return this.venues ? this.venues : null;
    },
    availableOffers() {
      return this.offers.filter((offer) => {
        // list only confirmed offers
        // TODO: when not list confirmed and invoiced offers?
        if (offer.confirmed) {
          return true;
        }
        return offer["@id"] === this.item.offer;
      });
    },
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    //region validation:
    nameErrors() {
      const errors = [];

      if (!this.$v.item.name.$dirty) {
        return errors;
      }

      has(this.violations, "name") && errors.push(this.violations.name);

      !this.$v.item.name.required && errors.push(this.$t("Field is required"));

      return errors;
    },
    topicsErrors() {
      const errors = [];

      if (!this.$v.item.topics.$dirty) {
        return errors;
      }
      has(this.violations, 'topics') && errors.push(this.violations.topics);
      !this.$v.item.topics.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    attendanceListTemplateErrors() {
      const errors = [];
      if (!this.$v.item.attendanceListTemplate.$dirty) {
        return errors;
      }
      has(this.violations, "attendanceListTemplate") && errors.push(this.violations.attendanceListTemplate);
      !this.$v.item.attendanceListTemplate.required && errors.push(this.$t("Field is required"));

      return errors;
    },
    noOnlineErrors() {
      const errors = [];

      if (!this.$v.item.noOnline.$dirty) {
        return errors;
      }

      has(this.violations, "noOnline") && errors.push(this.violations.noOnline);
      return errors;
    },
    offerErrors() {
      const errors = [];

      if (!this.$v.item.offer.$dirty) {
        return errors;
      }

      has(this.violations, "offer") && errors.push(this.violations.offer);
      return errors;
    },
    timeSlotStartErrors() {
      const errors = [];
      if (!this.$v.item.timeSlot.start.$dirty) {
        return errors;
      }

      has(this.violations, "timeSlot.start") &&
        errors.push(this.violations.timeSlot.start);

      !this.$v.item.timeSlot.start.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    timeSlotEndErrors() {
      const errors = [];
      if (!this.$v.item.timeSlot.end.$dirty) {
        return errors;
      }

      has(this.violations, "timeSlot.end") &&
        errors.push(this.violations.timeSlot.end);

      !this.$v.item.timeSlot.end.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    teachingUnitsErrors() {
      const errors = [];
      if (!this.$v.item.teachingUnits.$dirty) {
        return errors;
      }

      has(this.violations, "teachingUnits") &&
        errors.push(this.violations.timeSlot.end);

      !this.$v.item.teachingUnits.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    speakerErrors() {
      const errors = [];

      if (!this.$v.item.speaker.$dirty) {
        return errors;
      }

      has(this.violations, "speaker") && errors.push(this.violations.speaker);

      !this.$v.item.speaker.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    coSpeakerErrors() {
      const errors = [];

      if (!this.$v.item.coSpeaker.$dirty) {
        return errors;
      }

      has(this.violations, "coSpeaker") &&
        errors.push(this.violations.coSpeaker);
      return errors;
    },
    feeErrors() {
      const errors = [];
      if (!this.$v.item.fee.$dirty) {
        return errors;
      }

      has(this.violations, "fee") && errors.push(this.violations.fee);

      !this.$v.item.fee.required && errors.push(this.$t("Field is required"));

      return errors;
    },
    maxParticipantsErrors() {
      const errors = [];
      if (!this.$v.item.maxParticipants.$dirty) {
        return errors;
      }

      has(this.violations, "maxParticipants") &&
        errors.push(this.violations.maxParticipants);

      !this.$v.item.maxParticipants.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    seminarContentErrors() {
      const errors = [];

      if (!this.$v.item.seminarContent.$dirty) {
        return errors;
      }

      has(this.violations, "seminarContent") &&
        errors.push(this.violations.seminarContent);
      return errors;
    },
    additionalConfirmationTextErrors() {
      const errors = [];

      if (!this.$v.item.additionalConfirmationText.$dirty) {
        return errors;
      }

      has(this.violations, "additionalConfirmationText") &&
        errors.push(this.violations.additionalConfirmationText);
      return errors;
    },
    joinUrlErrors() {
      const errors = [];
      if (!this.$v.item.externalMeeting.joinUrl.$dirty) {
        return errors;
      }

      has(this.violations, "externalMeeting.joinUrl") && errors.push(this.violations.externalMeeting.joinUrl);

      !this.$v.item.externalMeeting.joinUrl.required && errors.push(this.$t("Field is required"));
      !this.$v.item.externalMeeting.joinUrl.url && errors.push(this.$t("Not a valid url"));

      return errors;
    },
    violations() {
      return this.errors || {};
    },
    //endregion

    //region data mappers
    ...mapGetters("timeslot", { findTimeSlot: "find" }),
    ...mapGetters("speaker", { speakerItems: "list" }),
    ...mapGetters("attendanceListTemplate", { attendanceListTemplateItems: "list" }),
    //endregion

    availableSpeakers() {
      return this.speakersForActiveTenant?.filter(
        (speaker) => speaker["@id"] !== this.item.coSpeaker
      );
    },
    availableCoSpeakers() {
      return this.speakersForActiveTenant?.filter(
        (speaker) => speaker["@id"] !== this.item.speaker
      );
    },
    defaultModuleFee() {
      if (this.currentSeminarSettings === null) {
        return 0;
      }
      return this.currentSeminarSettings.defaultModuleFee.toString();
    },
    defaultParticipants() {
      if (this.currentSeminarSettings === null) {
        return 0;
      }
      return this.currentSeminarSettings.maxParticipants.toString();
    },
    availableTopics() {
      return this.groupedTopics;
    },
    resolvedTimeSlot() {
      if (typeof this.item.timeSlot === "object") {
        return this.item.timeSlot;
      }
      let lTimeSlot = this.findTimeSlot(this.item.timeSlot);
      if (lTimeSlot === null) {
        this.retrieveTimeSlot(decodeURIComponent(this.item.timeSlot));
      }
      this.setTimeSlot(lTimeSlot);
      return lTimeSlot;
    },
    attendanceListTemplates() {
      return Object.values(this.attendanceListTemplatesById) ?? [];
    }
  },
  validations: {
    item: {
      name: {
        required: requiredIf(function () {
          return this.isInhouse;
        }),
      },
      topics: {
        required,
      },
      timeSlot: {
        start: {
          required,
        },
        end: {
          required,
        },
      },
      teachingUnits: {
        required,
      },
      attendanceListTemplate: {
        required,
      },
      speaker: {
        required,
      },
      coSpeaker: {},
      fee: {
        required,
      },
      maxParticipants: {
        required,
      },
      seminarContent: {},
      additionalConfirmationText: {},
      noOnline: {},
      offer: {},
      externalMeeting: {
        joinUrl: {
          required: requiredIf(function () {
            return this.externalMeetingToggle
          }),
          url
        }
      }
    },
  },
  methods: {
    log(e) {
      console.log(e);
    },
    ...mapActions({
      offerGetSelectedItems: "offer/fetchSelectItems",
      venueGetSelectedItems: "venue/fetchSelectItems",
    }),
    ...mapActions("timeslot", { retrieveTimeSlot: "load" }),
    setTimeSlot(timeSlot) {
      this.values.timeSlot = timeSlot;
    },
    templateSelected(target, textTemplate) {
      if (textTemplate && target) {
        this.$set(this.item, target, textTemplate);
      }
    },
    onStartDateSet() {
      this.startDateSet = true;
    },
    onEndDateSet() {
      this.endDateSet = true;
    },
    toggleExternalMeetingRoom() {
      if (!this.externalMeetingToggle) {
        this.item.externalMeeting.joinUrl = null
        this.item.externalMeeting.title = null
        this.item.externalMeeting.password = null
        this.$nextTick(() => { this.$v.$reset(); });
      }
    },
    offerFilter(item, queryText) {
      return (item.number.toLowerCase().indexOf(queryText.toLowerCase())) !== -1;
    },
    onOfferAcUpdated(selectedOffer) {
      if (selectedOffer) {
        this.item.offer = selectedOffer["@id"];
      } else {
        this.item.offer = null;
      }
    },
    topicFilter(item, queryText) {
      return (item.element.name.toLowerCase().indexOf(queryText.toLowerCase())) !== -1;
    }
  },
  watch: {
    "resolvedTimeSlot.start": {
      handler: function (after) {
        if (false === this.endDateSet) {
          this.resolvedTimeSlot.end = after;
        }
      },
      deep: true,
    },
    "item.noOnline": {
      handler: function () {
        if (this.item.noOnline === false && this.item.presenceSeminar === true) {
          this.item.presenceSeminar = false;
          this.item.venue = null;
        }
      },
      deep: true,
    },
    "item.presenceSeminar": {
      handler: function () {
        if (this.item.presenceSeminar === false) {
          this.item.venue = null;
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.speakersForActiveTenant = await this.getSpeakersForActiveTenant();
    // this.fetchAllTopics({pagination: false});
    this.offerGetSelectedItems();
    this.venueGetSelectedItems();
  },
  created(){
    this.externalMeetingToggle = this.item.externalMeeting !== null && this.item.externalMeeting.joinUrl !== null
    this.selectedOffer = this.item.offer !== null ? this.offers.find((offer) => this.item.offer['@id'] === offer['@id']) : null
  }
};
</script>
