<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <SeminarCombinedForm :errors="violationsSeminar" :values="item" :loading="isLoadingSeminar"
          :allow-name="allowName" :is-enabled="isEnabled" :is-cancelled="isCancelled" :is-completed="isCompleted"
          ref="seminarCombinedDialogForm" />
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="showDialog = false">{{ $t('Close') }}</v-btn>
        <v-spacer />
        <v-btn class="mainAccent lighten-3" dark @click="resetForm" :disabled="isEnabled">{{ $t('Reset') }}</v-btn>
        <v-btn color="primary" @click="sendForm">{{ $t('Submit') }}</v-btn>
      </v-card-actions>
    </v-card>
    <Loading :visible="isLoadingSeminar" />
  </v-dialog>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import SeminarCombinedForm from './Form';
import NotificationMixin from '../../mixins/NotificationMixin';
import Loading from '@/components/Loading';
import { mapActions } from 'vuex';
import SeminarMixin from '@/mixins/SeminarMixin';

const servicePrefix = 'Seminar';

export default {
  name: 'SeminarCombinedDialog',
  servicePrefix,
  mixins: [NotificationMixin, SeminarMixin],
  components: {
    Loading,
    SeminarCombinedForm
  },
  props: {
    value: Boolean,
    title: {
      type: String,
      required: true
    },
    seminarItem: {
      type: Object,
      required: false
    },
    seminarModuleItem: {
      type: Object,
      required: false
    },
    handleCreated: {
      type: Function,
      required: false
    },
    handleUpdated: {
      type: Function,
      required: false
    },
    allowName: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      item: {},
      createMessage: this.$t('SeminarCreated'),
      updateMessage: this.$t('SeminarUpdated'),
      meetingType: 'bbb'
    };
  },
  computed: {
    ...mapFields('seminar', {
      handledSeminar: 'handled',
      violationsSeminar: 'violations',
      isLoadingSeminar: 'isLoading',
      errorSeminar: 'error'
    }),
    ...mapFields('seminarModule', {
      isLoadingModule: 'isLoading',
      violationsModule: 'violations',
      errorModule: 'error'
    }),
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      }
    },
    isCreateForm() {
      return this.seminarItem === null && this.seminarModuleItem === null;
    },
    isEnabled() {
      if (this.seminarItem && this.seminarModuleItem) {
        return this.seminarItem.enabled && this.seminarModuleItem.enabled;
      }
      return false;
    },
    isCancelled() {
      if (this.seminarModuleItem) {
        return this.seminarModuleItem.cancelled;
      }
      return false;
    },
    isCompleted() {
      if (this.seminarModuleItem) {
        return this.seminarModuleItem.completed;
      }
      return false;
    }
  },
  methods: {
    ...mapActions('seminar', {
      seminarActions: 'action',
      updateSeminar: 'update',
      resetData: 'resetData',
      retrieve: 'load'
    }),
    ...mapActions('seminarModule', {
      updateModule: 'update'
    }),
    createItem(seminarItem = null, moduleItem = null) {
      return this.getCombinedObject(seminarItem, moduleItem);
    },
    async sendForm() {
      if (this.isValidForm()) {
        let model = this.$refs.seminarCombinedDialogForm.$v.item.$model;

        // remove unused external meeting
        if (model.externalMeeting.joinUrl === null) {
          delete model['externalMeeting'];
        }

        if (this.handleCreated && this.isCreateForm) {
          this.seminarActions({ action: 'seminar-create', data: model, dtoResponse: true });
        }
        if (this.handleUpdated && false === this.isCreateForm) {
          let informAboutMeetingTypeChange = false;
          if (this.seminarItem.enabled && !this.seminarModuleItem.cancelled && this.seminarModuleItem.confirmed) {
            let currentMeetingType = model.externalMeeting ? 'ext' : 'bbb';
            if ((currentMeetingType != this.meetingType)) {
              informAboutMeetingTypeChange = await this.$confirm(this.$t('MeetingTypeChanged'), {
                buttonTrueText: this.$t('Yes'),
                buttonFalseText: this.$t('No')
              });
            }
          }

          await this.seminarActions({
            ...(informAboutMeetingTypeChange === true
              ? {
                params: [{ key: 'informAboutMeetingTypeChange', value: true }]
              }
              : {}),
            action: 'seminar-update',
            item: this.seminarItem,
            data: model,
            dtoResponse: true,
            method: 'PUT',

          });

          this.handleUpdated(this.seminarItem);
        }
      }
    },
    reset() {
      this.$refs.seminarCombinedDialogForm.$v.$reset();
    },
    resetForm() {
      this.item = this.createItem(this.seminarItem, this.seminarModuleItem);
      if (this.$refs['seminarCombinedDialogForm']) {
        this.$refs.seminarCombinedDialogForm.$v.$reset();
      }
    },
    isValidForm() {
      this.$refs.seminarCombinedDialogForm.$v.$touch();
      return !this.$refs.seminarCombinedDialogForm.$v.$invalid;
    }
  },
  watch: {
    seminarItem() {
      this.resetForm();
    },
    seminarModuleItem() {
      this.resetForm();
    },
    handledSeminar(handledSeminar) {
      if (!handledSeminar) {
        return;
      }
      if (this.handleCreated && this.isCreateForm) {
        this.showMessage(this.createMessage);
        this.handleCreated(this.handledSeminar);
      }
      if (this.handleUpdated && false === this.isCreateForm) {
        this.showMessage(this.updateMessage);
        this.handleUpdated(this.handledSeminar);
      }
      this.showDialog = false;
    },
    errorSeminar(message) {
      message && this.showError(message);
    }
  },
  created() {
    this.item = this.createItem(this.seminarItem, this.seminarModuleItem);
    if (false === this.isCreateForm) {
      this.meetingType = this.item.externalMeeting.joinUrl !== null ? 'ext' : 'bbb';
    }
  }
};
</script>
